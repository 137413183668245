import React, { useEffect, useState } from "react"
import { Flex, Select, Input, FormControl, FormLabel } from "@chakra-ui/core"
import firebase from "gatsby-plugin-firebase"
import { groupBy, startCase } from "lodash"
import Fuse from "fuse.js"
import Layout from "../components/Layout"
import BusinessListingCard from "../components/BusinessListingCard"
import { H1, H4 } from "../components/Typography"
import SEO from "../components/SEO"

export default function BusinessListing({ data }) {
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [searchText, setSearchText] = useState("")

  const businessListings =
    data.allBusinessListing.edges.map(edge => edge.node) || []

  const blgroups = groupBy(businessListings, "businessListingCategories.name")
  const categories = Object.keys(blgroups) || []

  const options = {
    // Search in `author` and in `tags` array
    keys: ["name"],
  }

  const sortedBusinessListings =
    selectedCategory !== "all"
      ? businessListings.filter(
        bl => bl.businessListingCategories.name === selectedCategory
      )
      : businessListings

  const fuse = new Fuse(sortedBusinessListings, options)

  const result = fuse.search(searchText)

  useEffect(() => {
    firebase.analytics().setCurrentScreen("business_listing")
  }, [])
  return (
    <Layout p={[4, 6]}>
      <SEO
        title="Business Listings"
        description="Laudium Today online directory connects you with businesses in your community. It's your first and last stop when searching your local area for a restaurant, a store, home based or even a plumber!"
      />
      <H1 textAlign="center">Business Listings</H1>
      <Flex mt={4} width="100%" direction="column">
        <Flex
          width="100%"
          background="linear-gradient(100deg, #64b9ed,#1b95e0);"
          borderRadius={4}
          p={4}
          color="white"
          direction={["column", "row"]}
        >
          <FormControl width="100%">
            <FormLabel htmlFor="category">Category:</FormLabel>
            <Select
              placeholder="Select category"
              backgroundColor="white"
              id="category"
              name="category"
              value={selectedCategory}
              color="black"
              onChange={e => setSelectedCategory(e.target.value)}
            >
              <option key="alll" value="all">
                All
              </option>
              {categories.map(category => (
                <option key={category} value={category}>
                  {startCase(category)}
                </option>
              ))}
            </Select>
          </FormControl>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <FormControl width="100%">
            <FormLabel htmlFor="keywords">Keywords:</FormLabel>
            <Input
              variant="outline"
              type="text"
              id="keywords"
              name="keywords"
              backgroundColor="white"
              color="black"
              value={searchText}
              onChange={e => setSearchText(e.target.value || "")}
            />
          </FormControl>
        </Flex>
        <Flex
          mt={8}
          wrap="wrap"
          style={{ gap: "24px" }}
          justify="space-between"
        >
          {searchText.length > 0 ? (
            <>
              {result.length > 0 ? (
                <>
                  <H4>{result.length} Results</H4>
                  {result.map(listing => (
                    <BusinessListingCard listing={listing.item} />
                  ))}
                </>
              ) : (
                <H4>No Results</H4>
              )}
            </>
          ) : (
            <>
              {sortedBusinessListings.map(listing => (
                <BusinessListingCard listing={listing} url={`https://www.laudiumtoday.co.za/listings/${listing.fields?.slug}`} />
              ))}
            </>
          )}
        </Flex>
      </Flex>
    </Layout>
  )
}

export const pageQuery = graphql`
  query businessListings {
    allBusinessListing {
      edges {
        node {
          address
          brochureUrl
          businessListingCategories {
            name
          }
          fields {
            slug
          }
          contactNumber
          alternateContactNumber
          emailAddress
          id
          logoUrl
          name
          description
          videoLink
          website
          tradingHours {
            Monday {
              closed
              end
              start
              index
              day
            }
            Tuesday {
              closed
              day
              end
              index
              start
            }
            Wednesday {
              closed
              day
              end
              index
              start
            }
            Thursday {
              closed
              day
              end
              index
              start
            }
            Friday {
              closed
              day
              end
              index
              start
              additional
            }
            Friday_Additional {
              closed
              day
              end
              index
              start
            }
            Saturday {
              closed
              day
              end
              index
              start
            }
            Sunday {
              closed
              day
              end
              index
              start
            }
          }
        }
      }
    }
  }
`
